<template>
  <Browse
    :columns="[
      'due',
      'date',
      'amount',
      'concept',
      'payment_method',
      'amount_tendered',
      'change',
    ]"
    :formats="{
      date: 'Date',
      amount: 'Money',
      amount_tendered: 'Money',
      change: 'Money',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="payments"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
