var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'due',
    'date',
    'amount',
    'concept',
    'payment_method',
    'amount_tendered',
    'change' ],"formats":{
    date: 'Date',
    amount: 'Money',
    amount_tendered: 'Money',
    change: 'Money',
  },"itemsPerPage":"10","searchOnStart":true,"table":"payments"}})}
var staticRenderFns = []

export { render, staticRenderFns }